<template>
  <div>
    <a-row>
      <a-col
        class="collapse-card"
        v-for="paymentCard in paymentCardRows"
        :key="paymentCard.id"
        :span="24"
      >
        <div class="head" @click="onClickOpenCollapse(paymentCard.id)">
          <a-row :gutter="20">
            <a-col class="id cprimary" :span="1">
              <span class="ico">
                <a-icon type="file-text" />
                <font class="num">{{ paymentCard.id }} </font>
              </span>
            </a-col>

            <a-col class="id-name" :span="11"> CARTA DE CRÉDITO </a-col>

            <a-col class="value" :span="9">
              <a-tag :color="'blue'">
                {{
                  formatPricePtBr(
                    tempContract[
                      `payment_methods_payment_card_total_value_${paymentCard.id}`
                    ]
                  )
                }}
              </a-tag>

              <a-tag :color="'red'">
                {{
                  formatPricePtBr(
                    tempContract[
                      `payment_methods_payment_card_contract_profit_discount_value_${paymentCard.id}`
                    ]
                  )
                }}
              </a-tag>
            </a-col>

            <a-col class="a-right" :span="3">
              <a-button
                class="ml-5"
                v-if="
                  paymentCard.id > 1 && paymentCardRows.length == paymentCard.id
                "
                size="small"
                type="danger"
                shape="circle"
                icon="delete"
                @click="removePaymentCard(paymentCard.id - 1)"
                ghost
              >
              </a-button>
            </a-col>
          </a-row>
        </div>

        <div class="content" v-if="openCollapse == paymentCard.id">
          <PaymentCardCollapseRows
            :contract="contract"
            :tempContract="tempContract"
            :form="form"
            :paymentCardId="paymentCard.id"
            :reservation_total_price="reservation_total_price"
            @verifyTotal="verifyTotal"
          />
        </div>
      </a-col>
    </a-row>

    <div class="a-center mb-40 mt-20">
      <a-button
        type="primary"
        @click="addPaymentCard(paymentCardRows.length)"
        ghost
      >
        Adicionar carta de crédito
      </a-button>
    </div>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";
import PaymentCardCollapseRows from "../collapses/PaymentCardCollapseRows.vue";

export default {
  name: "PaymentCardTab",
  components: { PaymentCardCollapseRows },
  props: {
    contract: Object,
    tempContract: Object,
    form: Object,
    customerForm: Object,
    reservation_total_price: Number,
  },
  mixins: [formatThings],
  data() {
    return {
      openCollapse: 0,
      paymentCardRows: [
        {
          id: 1,
        },
      ],
    };
  },
  mounted() {
    if (this.tempContract["payment_card_rows"] != undefined) {
      this.paymentCardRows = JSON.parse(this.tempContract["payment_card_rows"]);
    }

    this.tempContract["payment_card_rows"] = JSON.stringify(
      this.paymentCardRows
    );
  },
  methods: {
    verifyTotal() {
      this.$emit("verifyTotal", true);
    },
    calcPaymentCardTotal() {
      let total = 0;
      this.paymentCardRows.forEach((pCard) => {
        pCard;
      });

      this.payment_methods_payment_card_total_value = total;
    },
    addPaymentCard(id) {
      this.paymentCardRows.push({
        id: id + 1,
      });

      this.tempContract["payment_card_rows"] = JSON.stringify(
        this.paymentCardRows
      );

      this.$emit("PaymentCardTotal", true);
      this.$emit("verifyTotal", true);
    },
    removePaymentCard(index) {
      for (var i in this.paymentCardRows) {
        if (i == index) {
          this.paymentCardRows.splice(i, 1);
          break;
        }
      }

      this.tempContract["payment_card_rows"] = JSON.stringify(
        this.paymentCardRows
      );

      this.$emit("PaymentCardTotal", true);
      this.$emit("verifyTotal", true);
    },
    onClickOpenCollapse(id) {
      if (id == this.openCollapse) {
        this.openCollapse = 0;
      } else {
        this.openCollapse = id;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.ant-btn-danger
  border:  0 !important
  font-size: 17px !important
  box-shadow: none !important
.duplicate
  border:  0 !important
  color: #4B8AD6 !important
  box-shadow: none !important
  font-size: 17px !important
.collapse-card
  .content
    padding: 30px 20px 20px
    border: 1px solid #ccc
    margin-top: -14px
    border-radius: 0 0 6px 6px
    margin-bottom: 11px
    background: #fff
  .head
    cursor: pointer
    clear: both
    padding:  20px
    background: #fff
    border: 1px solid #ccc
    margin-bottom: 10px
    border-radius: 6px
    .id
      color: #474747
      font-weight: 600
      .ico
        position: relative
        font-size: 14px
      .num
        position: absolute
        right: -13px
        top: 0
        font-size: 11px
        color: #333
    .id-name
      padding-left: 20px !important
      color: #42413f
      font-weight: 500
      text-transform: uppercase
    .value
      font-size: 10px
      color: #42413f
      font-weight: 500
      text-transform: uppercase
</style>
