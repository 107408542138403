var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-row',{attrs:{"gutter":[20, 0]}},[_c('a-col',{attrs:{"span":6}},[_c('aFormItem',[_c('label',{class:'filled'},[_vm._v("Valor total da carta de crédito ")]),_vm._v(" "),_c('aInput',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
            `payment_methods_payment_card_total_value_${_vm.paymentCardId}`,
            {
              rules: [
                {
                  required: true,
                  message: 'Preencha todos números.',
                },
                {
                  validator: _vm.verifyValue,
                },
              ],
            },
          ]),expression:"[\n            `payment_methods_payment_card_total_value_${paymentCardId}`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Preencha todos números.',\n                },\n                {\n                  validator: verifyValue,\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",class:_vm.haveTransaction ? 'readonly' : '',attrs:{"placeholder":"Insira um valor"},on:{"blur":_vm.verifyTotal}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1),_vm._v(" "),(
            _vm.tempContract[
              `payment_methods_payment_card_transaction_id_${_vm.paymentCardId}`
            ]
          )?_c('span',{staticClass:"cancel-transaction"},[_c('a-popconfirm',{attrs:{"ok-text":"Sim","cancel-text":"Não","placement":"top"},on:{"confirm":_vm.onConfirmCancelCreditLetterTransaction},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("\n              Tem certeza que deseja cancelar "),_c('br'),_vm._v("a transação da carta de\n              crédito ID\n              "+_vm._s(_vm.tempContract?.[
                  `payment_methods_payment_card_transaction_credit_letter_id_${_vm.paymentCardId}`
                ])+"?\n            ")]},proxy:true}],null,false,1579081753)},[_vm._v(" "),_c('a-icon',{staticClass:"red c-pointer relative",staticStyle:{"right":"-10px"},attrs:{"type":"close-circle"}})],1)],1):_vm._e()],1)],1),_vm._v(" "),(
        _vm.creditLetter.list.length &&
        ['', undefined].includes(
          _vm.tempContract[
            `payment_methods_payment_card_transaction_id_${_vm.paymentCardId}`
          ]
        )
      )?_c('a-col',{staticClass:"mb-30 mt-0",staticStyle:{"margin-top":"-10px !important"},attrs:{"span":24}},[_c('a-alert',{staticClass:"f12 mb-10",staticStyle:{"border-radius":"3px"},attrs:{"type":"info","banner":"","message":"Selecione uma carta de crédito abaixo:"}}),_vm._v(" "),_c('aRow',[_c('aCol',{staticClass:"mt-10 mb-20 travel-input",attrs:{"span":6}},[_c('a-input',{attrs:{"placeholder":"Código + Enter"},on:{"pressEnter":function($event){return _vm.getCreditLetters()}},model:{value:(_vm.creditLetter.filters.code),callback:function ($$v) {_vm.$set(_vm.creditLetter.filters, "code", $$v)},expression:"creditLetter.filters.code"}})],1)],1),_vm._v(" "),_c('aTable',{staticClass:"travel-table last-itens f12",attrs:{"columns":_vm.creditLettersTableColumns,"data-source":_vm.creditLetter.list,"loading":_vm.creditLetter.loading,"pagination":false,"scroll":{ y: 200 }},on:{"change":_vm.creditLetterTableChange},scopedSlots:_vm._u([{key:"available_value",fn:function(record){return _c('div',{},[_vm._v("\n          R$\n          "+_vm._s(_vm._f("formatPrice")(record.available_value))+"\n        ")])}},{key:"action",fn:function(record){return _c('div',{staticClass:"travel-actions"},[_c('a-popconfirm',{attrs:{"ok-text":"Sim","cancel-text":"Não","placement":"topLeft"},on:{"confirm":function($event){return _vm.onClickEmitCreditLetter(record)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("\n              Tem certeza que deseja utilizar "),_c('br'),_vm._v("o crédito desta carta de\n              crédito?\n            ")]},proxy:true}],null,true)},[_vm._v(" "),_c('a',{staticClass:"cgreen"},[_c('a-icon',{attrs:{"slot":"prefix","type":"right-square"},slot:"prefix"})],1)])],1)}}],null,false,3736053551)})],1):_vm._e(),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('aFormItem',[_c('label',{class:'filled'},[_vm._v("Valor da Lucratividade")]),_vm._v(" "),_c('aInput',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
            `payment_methods_payment_card_total_profit_value_${_vm.paymentCardId}`,
            {
              rules: [
                {
                  required: true,
                  message: 'Preencha todos números.',
                },
                {
                  validator: !this.$root.isAdmin(),
                },
              ],
            },
          ]),expression:"[\n            `payment_methods_payment_card_total_profit_value_${paymentCardId}`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Preencha todos números.',\n                },\n                {\n                  validator: !this.$root.isAdmin(),\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira um valor"},on:{"blur":_vm.calcConstractualFine}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('aFormItem',[_c('label',{class:'filled'},[_vm._v("Multa contratual (%)")]),_vm._v(" "),_c('aInput',{directives:[{name:"currency",rawName:"v-currency",value:({
            currency: {
              prefix: '',
              suffix: '',
            },
            distractionFree: true,
            valueAsInteger: false,
            precision: 2,
            autoDecimalMode: false,
            allowNegative: false,
          }),expression:"{\n            currency: {\n              prefix: '',\n              suffix: '',\n            },\n            distractionFree: true,\n            valueAsInteger: false,\n            precision: 2,\n            autoDecimalMode: false,\n            allowNegative: false,\n          }"},{name:"decorator",rawName:"v-decorator",value:([
            `payment_methods_payment_card_contractual_fine_percentage_${_vm.paymentCardId}`,
            {
              rules: [
                {
                  validator: _vm.verifyPercentage,
                },
              ],
            },
          ]),expression:"[\n            `payment_methods_payment_card_contractual_fine_percentage_${paymentCardId}`,\n            {\n              rules: [\n                {\n                  validator: verifyPercentage,\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira um valor"},on:{"blur":_vm.calcConstractualFine}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('aFormItem',[_c('label',{class:'filled'},[_vm._v("Multa (R$)")]),_vm._v(" "),_c('aInput',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
            `payment_methods_payment_card_contractual_fine_value_${_vm.paymentCardId}`,
          ]),expression:"[\n            `payment_methods_payment_card_contractual_fine_value_${paymentCardId}`,\n          ]"}],staticClass:"travel-input readonly"},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('aFormItem',[_c('label',{class:'filled'},[_vm._v("Valor desconto lucratividade (R$)")]),_vm._v(" "),_c('aInput',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
            `payment_methods_payment_card_contract_profit_discount_value_${_vm.paymentCardId}`,
          ]),expression:"[\n            `payment_methods_payment_card_contract_profit_discount_value_${paymentCardId}`,\n          ]"}],staticClass:"travel-input readonly"},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('aFormItem',[_c('label',{class:'filled'},[_vm._v("Número do voyage")]),_vm._v(" "),_c('aInput',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `payment_methods_payment_card_voyage_id_${_vm.paymentCardId}`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório.',
                },
              ],
            },
          ]),expression:"[\n            `payment_methods_payment_card_voyage_id_${paymentCardId}`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório.',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"type":"number","placeholder":"Insira um valor"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }