<template>
  <div>
    <a-row :gutter="[20, 0]">
      <a-col :span="6">
        <aFormItem>
          <label :class="'filled'">Valor total da carta de crédito </label>
          <aInput
            class="travel-input"
            placeholder="Insira um valor"
            v-currency
            :class="haveTransaction ? 'readonly' : ''"
            @blur="verifyTotal"
            v-decorator="[
              `payment_methods_payment_card_total_value_${paymentCardId}`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Preencha todos números.',
                  },
                  {
                    validator: verifyValue,
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </aInput>

          <span
            class="cancel-transaction"
            v-if="
              tempContract[
                `payment_methods_payment_card_transaction_id_${paymentCardId}`
              ]
            "
          >
            <a-popconfirm
              ok-text="Sim"
              cancel-text="Não"
              placement="top"
              @confirm="onConfirmCancelCreditLetterTransaction"
            >
              <template #title>
                Tem certeza que deseja cancelar <br />a transação da carta de
                crédito ID
                {{
                  tempContract?.[
                    `payment_methods_payment_card_transaction_credit_letter_id_${paymentCardId}`
                  ]
                }}?
              </template>
              <a-icon
                class="red c-pointer relative"
                type="close-circle"
                style="right: -10px"
              />
            </a-popconfirm>
          </span>
        </aFormItem>
      </a-col>

      <a-col
        v-if="
          creditLetter.list.length &&
          ['', undefined].includes(
            tempContract[
              `payment_methods_payment_card_transaction_id_${paymentCardId}`
            ]
          )
        "
        class="mb-30 mt-0"
        :span="24"
        style="margin-top: -10px !important"
      >
        <a-alert
          type="info"
          class="f12 mb-10"
          banner
          message="Selecione uma carta de crédito abaixo:"
          style="border-radius: 3px"
        />

        <aRow>
          <aCol class="mt-10 mb-20 travel-input" :span="6">
            <a-input
              class=""
              placeholder="Código + Enter"
              v-model="creditLetter.filters.code"
              @pressEnter="getCreditLetters()"
            />
          </aCol>
        </aRow>

        <aTable
          class="travel-table last-itens f12"
          :columns="creditLettersTableColumns"
          :data-source="creditLetter.list"
          :loading="creditLetter.loading"
          :pagination="false"
          :scroll="{ y: 200 }"
          @change="creditLetterTableChange"
        >
          <div slot="available_value" slot-scope="record">
            R$
            {{ record.available_value | formatPrice }}
          </div>

          <div class="travel-actions" slot="action" slot-scope="record">
            <a-popconfirm
              ok-text="Sim"
              cancel-text="Não"
              placement="topLeft"
              @confirm="onClickEmitCreditLetter(record)"
            >
              <template #title>
                Tem certeza que deseja utilizar <br />o crédito desta carta de
                crédito?
              </template>
              <a class="cgreen">
                <a-icon slot="prefix" type="right-square" />
              </a>
            </a-popconfirm>
          </div>
        </aTable>
      </a-col>

      <a-col :span="6">
        <aFormItem>
          <label :class="'filled'">Valor da Lucratividade</label>

          <aInput
            class="travel-input"
            placeholder="Insira um valor"
            v-currency
            @blur="calcConstractualFine"
            v-decorator="[
              `payment_methods_payment_card_total_profit_value_${paymentCardId}`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Preencha todos números.',
                  },
                  {
                    validator: !this.$root.isAdmin(),
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </aInput>
        </aFormItem>
      </a-col>

      <a-col :span="6">
        <aFormItem>
          <label :class="'filled'">Multa contratual (%)</label>

          <aInput
            class="travel-input"
            placeholder="Insira um valor"
            @blur="calcConstractualFine"
            v-currency="{
              currency: {
                prefix: '',
                suffix: '',
              },
              distractionFree: true,
              valueAsInteger: false,
              precision: 2,
              autoDecimalMode: false,
              allowNegative: false,
            }"
            v-decorator="[
              `payment_methods_payment_card_contractual_fine_percentage_${paymentCardId}`,
              {
                rules: [
                  {
                    validator: verifyPercentage,
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </aInput>
        </aFormItem>
      </a-col>

      <a-col :span="6">
        <aFormItem>
          <label :class="'filled'">Multa (R$)</label>

          <aInput
            class="travel-input readonly"
            v-currency
            v-decorator="[
              `payment_methods_payment_card_contractual_fine_value_${paymentCardId}`,
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </aInput>
        </aFormItem>
      </a-col>

      <a-col :span="6">
        <aFormItem>
          <label :class="'filled'">Valor desconto lucratividade (R$)</label>

          <aInput
            class="travel-input readonly"
            v-currency
            v-decorator="[
              `payment_methods_payment_card_contract_profit_discount_value_${paymentCardId}`,
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </aInput>
        </aFormItem>
      </a-col>

      <a-col :span="6">
        <aFormItem>
          <label :class="'filled'">Número do voyage</label>

          <aInput
            class="travel-input"
            type="number"
            placeholder="Insira um valor"
            v-decorator="[
              `payment_methods_payment_card_voyage_id_${paymentCardId}`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório.',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </aInput>
        </aFormItem>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { parse } from "vue-currency-input";
import formatThings from "@/mixins/general/formatThings.js";
import creditLetterReusables from "@/components/credit-letters/mixins/creditLetterReusables";
import creditLetterTransactionReusables from "@/components/credit-letters/mixins/creditLetterTransactionReusables";

export default {
  name: "PaymentCardCollapseRows",
  props: {
    form: Object,
    contract: Object,
    tempContract: Object,
    customerForm: Object,
    reservation_total_price: Number,
    paymentCardId: Number,
  },
  mixins: [
    formatThings,
    creditLetterReusables,
    creditLetterTransactionReusables,
  ],
  data() {
    return {
      haveTransaction: false,
    };
  },
  beforeMount() {
    delete this.creditLettersTableColumns[1]; // customer
    delete this.creditLettersTableColumns[3];
    delete this.creditLettersTableColumns[5];
    delete this.creditLettersTableColumns[6];
    delete this.creditLettersTableColumns[7];
    delete this.creditLettersTableColumns[8];
    delete this.creditLettersTableColumns[9]; // created

    this.creditLetter.filters.status.selected = ["Ativo"];

    if (!this.$root.isAdmin()) {
      this.creditLetter.filters.customerId = this.tempContract.customer_id;
    }

    this.getCreditLetters();

    let creditLetterId =
      this.tempContract[
        `payment_methods_payment_card_transaction_credit_letter_id_${this.paymentCardId}`
      ];

    if (![undefined, ""].includes(creditLetterId))
      this.getCreditLetterById(creditLetterId);
  },
  mounted() {
    if (
      this.tempContract[
        `payment_methods_payment_card_contractual_fine_percentage_${this.paymentCardId}`
      ] == undefined
    ) {
      this.tempContract[
        `payment_methods_payment_card_contractual_fine_percentage_${this.paymentCardId}`
      ] = "100";
    }

    this.form.setFieldsValue({
      [`payment_methods_payment_card_total_value_${this.paymentCardId}`]:
        this.tempContract[
          `payment_methods_payment_card_total_value_${this.paymentCardId}`
        ],
      [`payment_methods_payment_card_total_profit_value_${this.paymentCardId}`]:
        this.tempContract[
          `payment_methods_payment_card_total_profit_value_${this.paymentCardId}`
        ],
      [`payment_methods_payment_card_contractual_fine_percentage_${this.paymentCardId}`]:
        this.tempContract[
          `payment_methods_payment_card_contractual_fine_percentage_${this.paymentCardId}`
        ],
      [`payment_methods_payment_card_contractual_fine_value_${this.paymentCardId}`]:
        this.tempContract[
          `payment_methods_payment_card_contractual_fine_value_${this.paymentCardId}`
        ],
      [`payment_methods_payment_card_contract_profit_discount_value_${this.paymentCardId}`]:
        this.tempContract[
          `payment_methods_payment_card_contract_profit_discount_value_${this.paymentCardId}`
        ],
      [`payment_methods_payment_card_voyage_id_${this.paymentCardId}`]:
        this.tempContract[
          `payment_methods_payment_card_voyage_id_${this.paymentCardId}`
        ],
    });

    setTimeout(() => {
      this.haveTransaction =
        this.tempContract[
          `payment_methods_payment_card_transaction_id_${this.paymentCardId}`
        ] || false;
      this.calcConstractualFine();
    }, 200);
  },
  methods: {
    verifyTotal() {
      this.$emit("verifyTotal");
    },
    onConfirmCancelCreditLetterTransaction() {
      let transactionId =
        this.tempContract[
          `payment_methods_payment_card_transaction_id_${this.paymentCardId}`
        ];

      let valueToEmit =
        this.tempContract[
          `payment_methods_payment_card_total_value_${this.paymentCardId}`
        ];

      if (valueToEmit) {
        valueToEmit = valueToEmit.replace(",", ".");

        this.$http
          .post("/credit-letter-transaction/update-field", {
            id: transactionId,
            field: "is_canceled",
            value: true,
          })
          .then(() => {
            this.createCreditCardTransaction({
              contract_id: this.tempContract.id,
              customer_id: this.tempContract.customer_id,
              is_canceled: 0,
              credit_letter_code: this.creditLetter.details.raw.code,
              user_id: this.$store.state.userData.id,
              currency: "brl",
              value: valueToEmit,
              operation: "refund",
              extra_info: `Extorno da transação ${
                this.tempContract[
                  `payment_methods_payment_card_operation_id_${this.paymentCardId}`
                ]
              }`,
              credit_letter_id: this.creditLetter.details.id,
            }).then(() => {
              this.$message.success("Transação cancelada com sucesso!");

              this.tempContract[
                `payment_methods_payment_card_transaction_credit_letter_id_${this.paymentCardId}`
              ] = "";
              this.haveTransaction = false;
              this.tempContract[
                `payment_methods_payment_card_transaction_id_${this.paymentCardId}`
              ] = "";
              this.tempContract[
                `payment_methods_payment_card_operation_id_${this.paymentCardId}`
              ] = "";

              this.$http.post("/contract/update-multi-meta", {
                id: this.tempContract.id,
                [`payment_methods_payment_card_transaction_credit_letter_id_${this.paymentCardId}`]:
                  "",
                [`payment_methods_payment_card_transaction_id_${this.paymentCardId}`]:
                  "",
                [`payment_methods_payment_card_operation_id_${this.paymentCardId}`]:
                  "",
              });

              this.getcreditLetters();
            });
          });
      }
    },
    onClickEmitCreditLetter(creditLetter) {
      let valueToEmit =
        this.tempContract[
          `payment_methods_payment_card_total_value_${this.paymentCardId}`
        ];

      if (valueToEmit) {
        valueToEmit = valueToEmit.replace(",", ".");
        if (
          parseFloat(valueToEmit) <= parseFloat(creditLetter.available_value)
        ) {
          this.createCreditCardTransaction({
            contract_id: this.tempContract.id,
            customer_id: this.tempContract.customer_id,
            is_canceled: 0,
            credit_letter_code: creditLetter.code,
            user_id: this.$store.state.userData.id,
            currency: "brl",
            value: valueToEmit,
            operation: "subtract",
            extra_info: "",
            credit_letter_id: creditLetter.id,
          }).then(({ data }) => {
            this.$message.success("Emissão realizada com sucesso!");
            this.getCreditLetterById(creditLetter.id);

            this.haveTransaction = true;

            this.tempContract[
              `payment_methods_payment_card_transaction_credit_letter_id_${this.paymentCardId}`
            ] = creditLetter.id;
            this.tempContract[
              `payment_methods_payment_card_transaction_id_${this.paymentCardId}`
            ] = data.id;
            this.tempContract[
              `payment_methods_payment_card_operation_id_${this.paymentCardId}`
            ] = data.transaction_id;

            this.$http.post("/contract/update-multi-meta", {
              id: this.tempContract.id,
              [`payment_methods_payment_card_transaction_credit_letter_id_${this.paymentCardId}`]:
                creditLetter.id,
              [`payment_methods_payment_card_transaction_id_${this.paymentCardId}`]:
                data.id,
              [`payment_methods_payment_card_operation_id_${this.paymentCardId}`]:
                data.transaction_id,
            });

            this.$http.post("/log/create", {
              user_id: this.$store.state.userData.id,
              module_id: this.tempContract.id,
              module: "contract",
              action: "emit-credit-letter-transaction",
              description: `<font class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</font> fez uma retirada de R$ ${valueToEmit} da carta de crédito <font class="id">ID ${creditLetter.id}</font>.`,
            });

            this.$http.post("/log/create", {
              user_id: this.$store.state.userData.id,
              module_id: creditLetter.id,
              module: "credit-letter",
              action: "emit-credit-letter-transaction",
              description: `<font class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</font> fez uma retirada de R$ ${valueToEmit} no contrato <font class="id">ID ${this.tempContract.id}</font>.`,
            });

            this.getcreditLetters();
          });
        } else {
          this.$message.warning(
            "O valor a ser retirado não pode ser maior do que o disponível."
          );
        }
      } else {
        this.$message.warning("Insira o valor a ser retirado.");
      }
    },
    calcConstractualFine() {
      let percentage = this.tempContract[
          `payment_methods_payment_card_contractual_fine_percentage_${this.paymentCardId}`
        ]
          ? parse(
              this.tempContract[
                `payment_methods_payment_card_contractual_fine_percentage_${this.paymentCardId}`
              ]
            )
          : 0,
        profitDiscount = this.tempContract[
          `payment_methods_payment_card_total_profit_value_${this.paymentCardId}`
        ]
          ? parse(
              this.tempContract[
                `payment_methods_payment_card_total_profit_value_${this.paymentCardId}`
              ]
            )
          : 0,
        profitFee = 0,
        profit = 0;

      profitFee = (percentage / 100) * profitDiscount;

      profit = profitDiscount - profitFee;

      this.form.setFieldsValue({
        [`payment_methods_payment_card_contractual_fine_value_${this.paymentCardId}`]:
          profitFee ? `${profitFee.toFixed(2)}`.replace(".", ",") : 0,
      });

      this.form.setFieldsValue({
        [`payment_methods_payment_card_contract_profit_discount_value_${this.paymentCardId}`]:
          profit ? `${profit.toFixed(2)}`.replace(".", ",") : 0,
      });
    },
    async verifyValue(rule, value, callback) {
      let theValue = parse(value);

      if (isNaN(theValue) || theValue == undefined) {
        theValue = 0;
      } else {
        if (parse(value) > parseFloat(this.reservation_total_price)) {
          callback(
            `O valor preenchido ultrapassa o total da reserva (R$ ${this.reservation_total_price}).`
          );
        } else {
          callback();
        }
      }
    },
    async verifyPercentage(rule, value, callback) {
      let theValue = parse(value);
      if (isNaN(theValue) || theValue == undefined) {
        theValue = 0;
      } else {
        if (parse(value) > 100) {
          callback(`Preencha um valor entre 0 a 100.`);
        } else {
          callback();
        }
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.cancel-transaction
  position: absolute
  right: 20px
</style>
